import React from 'react'

export default function BrandsTable() {
  return (
    <div><table className="table table-responsive text-center table-hover table-bordered">
    <thead className="table-secondary">
        <tr>
            <th>#</th>
            <th>عنوان </th>
            <th>عنوان فارسی </th>
            <th>توضیحات</th>
            <th>لوگو</th>
            <th>عملیات</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>1</td>
            <td>brand 1</td>
            <td>برند شماره 1</td>
            <td> توضیحات اجمالی در مورد این برند</td>
            <td>
                <img src="/assets/images/logo.png" width="50"/>
            </td>
            <td>
                <i className="fas fa-edit text-warning mx-1 hoverable_text pointer has_tooltip" title="ویرایش برند" data-bs-toggle="modal" data-bs-placement="top" data-bs-target="#add_brand_modal"></i>
                <i className="fas fa-times text-danger mx-1 hoverable_text pointer has_tooltip" title="حذف برند" data-bs-toggle="tooltip" data-bs-placement="top"></i>
            </td>
        </tr>
    </tbody>
</table>
<nav aria-label="Page navigation example" className="d-flex justify-content-center">
    <ul className="pagination dir_ltr">
        <li className="page-item">
        <a className="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">&raquo;</span>
        </a>
        </li>
        <li className="page-item"><a className="page-link" href="#">1</a></li>
        <li className="page-item"><a className="page-link" href="#">2</a></li>
        <li className="page-item"><a className="page-link" href="#">3</a></li>
        <li className="page-item">
        <a className="page-link" href="#" aria-label="Next">
            <span aria-hidden="true">&laquo;</span>
        </a>
        </li>
    </ul>
    </nav>
    </div>
  )
}
