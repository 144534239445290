import React from 'react'

const Notfound=()=> {
  return (
    <div>
      <img src="/assets/images/404.png" alt=""  className='notfound-img'/>
      <h1 className='notfound-h1'>متاسفانه درخواست مورد نظر پیدا نشد:(</h1>
    </div>
  )
}
export default Notfound;